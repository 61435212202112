var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header" }, [
    _c(
      "div",
      { staticClass: "collapse-btn", on: { click: _vm.collapseChage } },
      [
        !_vm.collapse
          ? _c("i", { staticClass: "el-icon-s-fold" })
          : _c("i", { staticClass: "el-icon-s-unfold" })
      ]
    ),
    _c("div", { staticClass: "logo" }, [_vm._v("后台管理系统")]),
    _c("div", { staticClass: "header-right" }, [
      _c(
        "div",
        { staticClass: "header-user-con" },
        [
          _c(
            "div",
            {
              staticClass: "btn-fullscreen",
              on: { click: _vm.handleFullScreen }
            },
            [
              _c(
                "el-tooltip",
                {
                  attrs: {
                    effect: "dark",
                    content: _vm.fullscreen ? "取消全屏" : "全屏",
                    placement: "bottom"
                  }
                },
                [_c("i", { staticClass: "el-icon-rank" })]
              )
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-dropdown",
                { on: { command: _vm.handleCommand } },
                [
                  _c("span", { staticClass: "el-dropdown-link" }, [
                    _vm._v(" " + _vm._s(_vm.currentTenant.name)),
                    _c("i", {
                      staticClass: "el-icon-arrow-down el-icon--right"
                    })
                  ]),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    _vm._l(_vm.tenantList, function(item, index) {
                      return _c(
                        "el-dropdown-item",
                        {
                          key: index,
                          nativeOn: {
                            click: function($event) {
                              return _vm.changeTenant(item)
                            }
                          }
                        },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._m(0),
          _c(
            "el-dropdown",
            { staticClass: "user-name", on: { command: _vm.handleCommand } },
            [
              _c("span", { staticClass: "el-dropdown-link" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.userInfo.name) +
                    "\n                    "
                ),
                _c("i", { staticClass: "el-icon-caret-bottom" })
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "el-dropdown-item",
                    { attrs: { command: "clearDictCache" } },
                    [_vm._v("清除缓存")]
                  ),
                  _c("el-dropdown-item", { attrs: { command: "loginout" } }, [
                    _vm._v("退出登录")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "user-avator" }, [
      _c("img", { attrs: { src: require("../../assets/img/img.jpg") } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }